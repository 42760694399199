<template>
  <div class="cw-footer">
    <v-row>
      <v-col
        md="5"
        cols="12"
      >
        <ul class="cw-footer__list">
          <li class="text-body-2">
            <b>Tact Finance Oy</b>
          </li>
          <li class="text-caption">
            Pitkämäenkatu 4 A
          </li>
          <li class="text-caption">
            20250 TURKU
          </li>
        </ul>

        <ul class="cw-footer__list pt-4">
          <li class="text-caption">
            <b v-translate class="mr-2">Email</b>
            <a
              id="cw-footer__mailto"
              href="mailto:asiakaspalvelu@tactfinance.fi"
              @click="$eventLogger.clickEvent($event)"
            >asiakaspalvelu@tactfinance.fi</a>
          </li>
          <li class="text-caption">
            <b v-translate>Business id</b>&nbsp;3246454-7
          </li>
        </ul>

        <ul class="cw-footer__list mt-4">
          <li
            v-translate="{
              openingTag: '<b>',
              closingTag: '</b>',
              openingTag2: '<i>',
              closingTag2: '</i>',
            }"
            class="text-caption"
            render-html="true"
          >
            The operations of %{ openingTag }Tact Finance Oy%{ closingTag } are supervised by the
            Financial Supervisory Authority, which has registered TactFinance Oy in the Lender and
            Peer Brokerage Register.
          </li>
        </ul>
      </v-col>
      <v-col
        class="footer-logo"
        cols="12"
        md="3"
      />
      <v-col
        class="footer-logo"
        cols="12"
        md="4"
      />
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'CwFooter',
};
</script>

<style lang="scss">
.cw-footer {
  padding: 16px;

  @include mq($from: md) {
    padding: 32px;
  }

  &__list {
    list-style: none;
    margin-right: 8px;
    padding-left: 0 !important;
    padding: 0;

    li {
      line-height: 24px;
      max-width: 300px;
    }
  }

  .footer-logo {
    @include mq($from: md) {
      margin: 0;
      text-align: right;
    }
  }

  &__signicat {
    max-width: 260px;
    position: relative;
    left: -12px;

    @include mq($from: sm) {
      max-width: 320px;
    }
  }
}
</style>
